<template>
      <section>
            <notification-bar></notification-bar>
            <nav-menu></nav-menu>
            <div>
                  <div class="page-content-lightestblue" v-if="cart?.items?.length > 0">
                        <!-- <div v-if="cart?.error">
                              <div class="error-section">
                                    <p>
                                          <b>Hinweis: </b>
                                          <span v-html="t('MSG_PAYMENT_ALERT')"></span>
                                    </p>
                              </div>
                        </div> -->
                        <div class="page-content-lightestblue__container">
                              <h2>{{ t("LBL_MY_CART") }}</h2>
                        </div>
                        <!-- Desktop View -->
                        <div class="page-content-lightestblue__container pb-10" v-if="isDesktop">
                              <div class="page-content-lightestblue__container-colLeft">
                                    <cart-products></cart-products>

                                    <div class="ocp-teaser">
                                          <a
                                                class="xxs:hidden xs:hidden sm:block md:block lg:block"
                                                v-if="cms?.desktop?.id && cms.desktop.imageName"
                                                :href="`${cms.desktop.url}`"
                                                target="_blank"
                                                :title="`${cms.desktop.tag}`"
                                                ><picture>
                                                      <source media="(min-width:600px)" :srcset="`${cms.desktop.imageName}`" />
                                                      <app-image :alt="`${cms.desktop.tag}`" :src="`${cms.desktop.imageName}`"></app-image> </picture
                                          ></a>
                                          <a
                                                class="xxs:block xs:block sm:hidden md:hidden lg:hidden"
                                                v-if="cms?.mobile?.id && cms.mobile.imageName"
                                                :href="`${cms.mobile.url}`"
                                                target="_blank"
                                                :title="`${cms.mobile.tag}`"
                                                ><picture>
                                                      <source media="(min-width:320px)" :srcset="`${cms.mobile.imageName}`" />
                                                      <app-image :alt="`${cms.mobile.tag}`" :src="`${cms.mobile.imageName}`"></app-image> </picture
                                          ></a>
                                    </div>
                                    <div class="coorder-products" v-if="products">
                                          <h2>{{ t("LBL_CO_ORDER") }}</h2>
                                          <h3>{{ t("LBL_CO_ORDER_SUB") }}</h3>
                                          <product-list v-if="totalSumWithVoucherDiscount > 0" :co-order="true"></product-list>
                                    </div>
                              </div>
                              <div class="page-content-lightestblue__container-colRight" v-if="totalSumWithVoucherDiscount > 0">
                                    <Checkout :products="products"></Checkout>
                              </div>
                        </div>

                        <!-- Mobile View -->
                        <div class="page-content-lightestblue__container pb-10" v-else>
                              <div class="page-content-lightestblue__container-colLeft">
                                    <cart-products></cart-products>

                                    <div class="ocp-teaser">
                                          <a
                                                class="xxs:hidden xs:hidden sm:block md:block lg:block"
                                                v-if="cms?.desktop?.id && cms.desktop.imageName"
                                                :href="`${cms.desktop.url}`"
                                                target="_self"
                                                :title="`${cms.desktop.tag}`"
                                                ><picture>
                                                      <source media="(min-width:600px)" :srcset="`${cms.desktop.imageName}`" />
                                                      <app-image :alt="`${cms.desktop.tag}`" :src="`${cms.desktop.imageName}`"></app-image> </picture
                                          ></a>
                                          <a
                                                class="xxs:block xs:block sm:hidden md:hidden lg:hidden"
                                                v-if="cms?.mobile?.id && cms.mobile.imageName"
                                                :href="`${cms.mobile.url}`"
                                                target="_self"
                                                :title="`${cms.mobile.tag}`"
                                                ><picture>
                                                      <source media="(min-width:320px)" :srcset="`${cms.mobile.imageName}`" />
                                                      <app-image :alt="`${cms.mobile.tag}`" :src="`${cms.mobile.imageName}`"></app-image> </picture
                                          ></a>
                                    </div>
                                    <div v-if="totalSumWithVoucherDiscount > 0 && !isDesktop">
                                          <Checkout :products="products"></Checkout>
                                    </div>
                              </div>
                              <div class="page-content-lightestblue__container-colRight coorder-products" v-if="products">
                                    <h2>{{ t("LBL_CO_ORDER") }}</h2>
                                    <h3>{{ t("LBL_CO_ORDER_SUB") }}</h3>
                                    <product-list v-if="totalSumWithVoucherDiscount > 0" :co-order="true"></product-list>
                              </div>
                        </div>
                  </div>

                  <div v-else><empty-cart></empty-cart></div>
            </div>
      </section>
</template>
<script>
      import ProductList from "@/components/Product/List";
      import CartProducts from "@/components/ShoppingCart/CartProducts";
      import Checkout from "@/components/ShoppingCart/Checkout";
      import EmptyCart from "@/components/ShoppingCart/EmptyCart";
      import NavMenu from "@/components/Menu/NavMenu";
      import NotificationBar from "@/components/NotificationBar/NotificationBar";
      import AppImage from "@/components/Image/Image";
      import {computed, onMounted, ref, watch} from "vue";
      import { useStore } from "vuex";
      import { useI18n } from "vue-i18n";
      import ShiftyBar from "@/components/ShiftyBar/ShiftyBar";

      export default {
            name: "ShoppingCart",
            components: {
                  NotificationBar,
                  ProductList,
                  CartProducts,
                  Checkout,
                  NavMenu,
                  EmptyCart,
                  AppImage,
                  ShiftyBar
            },
            setup() {
                  const { t } = useI18n();
                  const store = useStore();
                  //store.dispatch("cart/get");
                  //@todo by now the cart should have been called??
                  const products = computed(() => {
                        return store.getters["catalog/getProducts"];
                  });

                  const mediaQuery = "(min-width: 992px)";
                  const mediaQueryList = window.matchMedia(mediaQuery);
                  let isDesktop = ref(mediaQueryList.matches);
                  const cart = computed(() => {
                        return store.getters["cart/cart"];
                  });
                  watch(cart, (newVal) => {
                        if (newVal && newVal.items && newVal.items.length  === 0) {
                           store.dispatch("securepay/set", {});
                        }
                  });

                  onMounted(() => {
                        store.dispatch("cms/getMainPageContents");

                        // listen to screen size changes
                        mediaQueryList.addEventListener("change", () => {
                              isDesktop.value = mediaQueryList.matches;
                        });
                  });

                  return {
                        t,
                        products,
                        isDesktop,
                        mediaQueryList,
                        cart,
                        totalSumWithVoucherDiscount: computed(() => {
                              return store.getters["cart/totalSumWithVoucherDiscount"];
                        }),
                        cms: computed(() => {
                              let cms = store.getters["cms/getMainPageContents"];
                              cms = Object.values(cms);
                              if (cms && cms.length > 0) {
                                    return cms
                                          .filter(function (data) {
                                                return ["OCP_TEASER_DESKTOP_IMAGE", "OCP_TEASER_MOBILE_IMAGE"].includes(data.tag);
                                          })
                                          .reduce(
                                                (aggregate, item) => {
                                                      if (item.mobileOnly == 1) {
                                                            aggregate.mobile = { imageName: item.imageName, url: item.url, tag: item.tag, id: item.id };
                                                      } else {
                                                            aggregate.desktop = { imageName: item.imageName, url: item.url, tag: item.tag, id: item.id };
                                                      }
                                                      return aggregate;
                                                },
                                                { desktop: {}, mobile: {} }
                                          );
                              }
                              return {};
                        })
                  };
            }
      };
</script>

<style lang="postcss" scoped>
      .page-content-lightestblue {
            &__container {
                  @apply grid gap-4 grid-flow-row-dense  text-left;
                  @media screen and (max-width: 767px) {
                        width: 100%;
                        display: block;
                  }
                  h2 {
                        @apply mt-5;
                        grid-column: 1/3;
                        align-self: start;
                        display: block;
                        width: 91vw;
                        text-align: left;
                        @media screen and (max-width: 767px) {
                              @apply mx-2;
                        }
                  }
                  &.heading {
                        @apply px-2;
                  }
                  &-colLeft {
                        @apply col-span-1;
                        grid-column: 1/3;
                        align-self: start;
                        @media (max-width: 600px) {
                              @apply col-span-3;
                        }
                  }
                  &-colRight {
                        @apply col-span-1;
                        grid-column: 3/-1;
                        align-self: start;
                        height: 100%;
                        @media (max-width: 600px) {
                              @apply col-span-3;
                        }
                  }
                  &-colBottomLeft {
                        @apply col-span-2;
                        grid-column: 1/3;
                  }
                  @media (min-width: 992px) {
                        @apply grid-cols-3;
                  }
            }
      }
      .coorder-products {
            h2,
            h3 {
                  margin-left: 0;
                  @media (max-width: 767px) {
                        margin-left: 2.25rem;
                  }
                  @media (max-width: 600px) {
                        margin-left: 1.55rem;
                  }
            }
      }
      .ocp-teaser img {
            max-width: 100%;
            width: 940px;
            height: 120px;
            margin: 0 auto;

            @media (max-width: 767px) {
                  padding: 0 1rem;
            }

            @media (max-width: 600px) {
                  width: 460px;
            }
      }
      .error-section {
            display: flex;
            justify-content: center;
            background: #fffae6;
            height: 6rem;
            align-items: center;
      }
      @media screen and (max-width: 767px) {
            .error-section {
                  padding: 2rem;
                  height: auto;
            }
      }
</style>
