<template>
  <section class="checkout__summary">
    <div class="checkout__table">
      <div>{{ t("LBL_SUBTOTAL") }}</div>
      <div class="text-right type-caption">{{ totalSumWithVoucherDiscount }} °P</div>
      <!-- remove v-if="showVoucher()" -->
      <div class="col-span-2">
        <Voucher :module="'cart'"></Voucher>
      </div>
      <div>{{ t("LBL_SHIPPING_COST") }}</div>
      <div class="text-right type-caption">0 °P</div>

      <div class="checkout__table--summary border-t col-span-2"></div>

      <div class="type-number">
        {{ t("LBL_TOTAL") }}
      </div>
      <div class="text-right type-number">{{ numbersConvert.thousandSeparator(grandTotalWithVoucherDiscount) }} °P</div>

      <div v-if="isLoggedIn" class="flex flex-col justify-center">
        {{ t("LBL_POINTS_TO_REDEEM") }}
      </div>
      <div v-if="isLoggedIn" class="text-right type-caption">
        <text-input v-if="!isCheckout" cssClass="points-input" :key="grandTotalWithVoucherDiscount"
          @input="pointDiscountChange($event)" :value="customerPoints < grandTotalWithVoucherDiscount && customerPoints == pointsDiscount ? numbersConvert.thousandSeparator(customerPoints) : numbersConvert.thousandSeparator(pointsDiscount)
            " :numberOnly="true" :readonly="loading"></text-input>
        <div v-else class="text-right type-caption">{{ numbersConvert.thousandSeparator(pointsDiscount) }} °P</div>
      </div>
      <div v-if="alertText && !isCheckout" class="text-red-dark col-span-2" v-html="alertText"></div>
      <div v-if="alertTextAdditionalPayment && !isCheckout" class="text-red-dark col-span-2"
        v-html="alertTextAdditionalPayment"></div>
      <!-- <div v-if="cart?.error && !alertText && !alertTextAdditionalPayment" class="text-red-dark col-span-2" v-html="t('MSG_PAYMENT_ALERT')"></div> -->

      <div class="contents" v-if="isLoggedIn">
        <div>{{ t("LBL_CO_PAYMENT") }}</div>
        <div v-if="isValidPointsAmount || isCheckout || pointsDiscount" class="text-right type-caption"
          v-html="$filters.formatAmount(additionalPayment, 'EUR')"></div>
      </div>
      <div class="checkout__table--button border-t col-span-2 flex flex-col justify-center items-center">
        <Button v-if="!isLoggedIn" :css-class="'red'" :text="t('LBL_LOG_IN_AND_PAY')" class="w-full"
          @click="login()"></Button>
        <Button v-else-if="stepper == 1" :css-class="'green'" :text="t('LBL_GO_TO_THE_CHECKOUT')" class="w-full"
          @click="orderOverview()" :disabled="!isValid"></Button>
        <Button v-else-if="stepper == 2" :css-class="'green'" :text="t('LBL_CONTINUE')" class="w-full" @click="logger()"
          :disabled="!isValid"></Button>

        <!-- <div v-if="stepper == 3">
          <div v-if="cart?.items?.[0]?.product_group_name === 'PAYBACK_ARTICLE'">
            <div class="pt-2 pb-2 type-caption">
              Für Ihre Bestellung gelten die
              <a class="text-blue" href="/praemien/praemienaktionen/gutschein-agb" target="_blank" rel="noopener">AGB
                der PAYBACK Austria GmbH.</a>
              Details zum Widerrufsrecht finden Sie
              <a class="text-blue" href="/praemien/praemienaktionen/gutschein-gutschein-widerrufsbelehrung" target="_blank"
                rel="noopener">hier.</a>
              <div class="pt-2 pb-2 type-caption">
                Bei Kauf eines eGutscheins stimme ich mit Klick auf den Button "Jetzt kaufen" ausdrücklich zu, dass vor
                Ablauf der Widerrufsfrist mit der Ausführung des Vertrags begonnen wird. Mir ist
                bekannt, dass mit Beginn der Ausführung mein Widerrufsrecht erlischt.
              </div>
            </div>
            <Button :css-class="'green'" :text="t('LBL_BUY_NOW')" class="w-full" @click="submitOrder()"
              :disabled="!isValid || isDisabled" :readonly="loading"></Button>
            <div class="pt-2 pb-2 type-caption">Der Verkauf der Gutschein Bestellung erfolgt durch die PAYBACK Austria
              GmbH.</div>
            <a class="text-blue" href="https://www.payback.at/datenschutz" target="_blank"
              rel="noopener">Datenschutzhinweise</a>
          </div>
          <div v-else>
            <div class="pt-2 pb-2 type-caption">
              Für Ihre Bestellungen gelten die
              <a class="text-blue" href="/praemien/praemienaktionen/praemien-agb" target="_blank" rel="noopener">AGB der
                cadooz rewards GmbH.</a>
              Details zu Ihrem Widerrufsrecht finden Sie
              <a class="text-blue" href="/praemien/praemienaktionen/gutschein-widerrufsbelehrung" target="_blank"
                rel="noopener">hier.</a>
            </div>
            <Button :css-class="'green'" :text="t('LBL_BUY_NOW')" class="w-full" @click="submitOrder('orderSubmitted')"
              :disabled="!isValid || isDisabled" :readonly="loading"></Button>
            <div class="pt-2 pb-2 type-caption">
              Der Verkauf und Versand Ihrer Prämienbestellung erfolgt durch die cadooz rewards GmbH. Wenn Sie auf den
              Button „Jetzt kaufen” klicken, wird PAYBACK Ihre Bestellung an die cadooz rewards
              GmbH weiterleiten. Einzelheiten finden Sie in den
              <a class="text-blue" href="https://www.payback.at/datenschutz" target="_blank"
                rel="noopener">Datenschutzhinweisen.</a>
            </div>
          </div>
        </div> -->
        <!-- Commented trusted for now - we may need this here in future -->
        <!-- <div id="payBackTrustbadge"></div> -->
        <div v-if="stepper == 3">
          <div v-if="cart?.items?.[0]?.product_group_name === 'PAYBACK_ARTICLE'">
            <div class="pt-2 pb-2 type-caption">
              <input type="checkbox" id="termsCheckbox" v-model="termsAccepted" name="termsAccepted" class="mr-2"
                @change="onCheckboxChange">
              <label for="termsCheckbox">Ich akzeptiere die <a class="text-blue"
                  href="/praemien/praemienaktionen/gutschein-agb" target="_blank" rel="noopener">Allgemeinen
                  Geschäftsbedingungen zum Erwerb von
                  Gutscheinen im PAYBACK Prämienshop</a> der Payback GmbH („AGB“).
                <br>
                <span>Informationen zu Ihrem Widerrufsrecht finden Sie <a class="text-blue"
                    href="/praemien/praemienaktionen/gutschein-widerrufsbelehrung" target="_blank"
                    rel="noopener">hier.</a> bzw.
                  in den oben genannten AGB.</span></label>
            </div>
            <div class="pt-2 pb-2 type-caption">
              <input type="checkbox" id="conditionsCheckbox" v-model="conditionsAccepted" name="conditionsAccepted"
                class="mr-2" @change="onCheckboxChange">
              <label for="conditionsCheckbox">Ich stimme in Bezug auf den Gutscheinerwerb ausdrücklich zu,
                dass die Payback GmbH vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrags beginnt.
                Mir ist bekannt, dass durch diese Zustimmung mit Beginn der Vertragserfüllung mein
                Widerrufsrecht erlischt.</label>
            </div>
            <div class="errorMessage text-red my-2 text-xs" v-show="!areBothCheckboxesChecked">Bitte akzeptieren Sie
              die AGB und Widerrufsbelehrung der PAYBACK Austria GmbH, um fortzufahren.</div>
            <Button :css-class="'green'" :text="t('LBL_BUY_NOW')" class="w-full" @click="submitOrder()"
              :disabled="!areBothCheckboxesChecked || !isValid || isDisabled" :readonly="loading"></Button>
            <span class="flex pt-3">Der Verkauf der Gutschein Bestellung erfolgt durch die PAYBACK
              GmbH.</span>
            <a class="text-blue" href="https://www.payback.at/datenschutz" target="_blank"
              rel="noopener">Datenschutzhinweise.</a>
          </div>
          <div v-else>
            <div class="pt-2 pb-2 type-caption">
              Für Ihre Bestellungen gelten die
              <a class="text-blue" href="/praemien/praemienaktionen/praemien-agb" target="_blank" rel="noopener">AGB der
                cadooz rewards GmbH.</a>
              Details zu Ihrem Widerrufsrecht finden Sie
              <a class="text-blue" href="/praemien/praemienaktionen/widerrufsbelehrung" target="_blank"
                rel="noopener">hier.</a>
            </div>
            <Button :css-class="'green'" :text="t('LBL_BUY_NOW')" class="w-full" @click="submitOrder('orderSubmitted')"
              :disabled="!isValid || isDisabled" :readonly="loading"></Button>
            <div class="pt-2 pb-2 type-caption">
              Der Verkauf und Versand Ihrer Prämienbestellung erfolgt durch die cadooz rewards GmbH. Wenn Sie auf den
              Button „Jetzt kaufen” klicken, wird PAYBACK Ihre Bestellung an die cadooz rewards
              GmbH weiterleiten. Einzelheiten finden Sie in den
              <a class="text-blue" href="https://www.payback.at/datenschutz" target="_blank"
                rel="noopener">Datenschutzhinweisen.</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <app-image :alt="'separation-border'" class="roughBorder"
        :src="require('../../assets/img/rough-border.png')"></app-image>
    </div>
    <div class="continue-shopping">
      <router-link to="/" :title="t('LBL_CONTINUE_SHOPPING')"><svg width="24" height="24" viewBox="0 0 24 24"
          fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z" fill="#0046AA" />
        </svg>{{ ` ${t("LBL_CONTINUE_SHOPPING")}` }}</router-link>
    </div>
  </section>
</template>

<script>
import Button from "@/components/Button/Button";
import TextInput from "@/components/Inputs/TextInput";
import Voucher from "@/components/ShoppingCart/Voucher";
import AppImage from "@/components/Image/Image";
import { computed, watch, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useCheckout } from "@/composables/useCheckout";
import EventBus from "@/helpers/event-bus";
import numbersConvert from "@/helpers/numbersConvert";

import { useRoute, useRouter } from "vue-router";

export default {
  name: "Checkout",
  components: {
    Button,
    TextInput,
    AppImage,
    Voucher,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    products: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup(props, { emit }) {
    const isDisabled = ref(false);
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const termsAccepted = ref(false);
    const conditionsAccepted = ref(false);


    const showVoucher = () => {
      return route.meta.pageType != "bestellen";
    };

    const areBothCheckboxesChecked = computed(() => {
      return termsAccepted.value && conditionsAccepted.value;
    })

    EventBus.on("updatedAlertText", (arg) => {
      alertText.value = arg.alertText;
      isValid.value = arg.isValid;
    });
    EventBus.on("updatedAlertTextAdditionalPayment", (arg) => {
      alertTextAdditionalPayment.value = arg.alertTextAdditionalPayment;
      isValid.value = arg.isValid;
    });
    EventBus.on("submitOrderResponse", (arg) => {
      isValid.value = arg?.success;
    });
    const { alertText, alertTextAdditionalPayment, isValidPointsAmount, applicableDiscount, initUserInputValue, onPointsRedeem, isValid } = useCheckout("cart");

    const isLoggedIn = computed(() => {
      return store.getters["account/isLoggedIn"];
    });

    const securepayPaymentType = computed(() => {
      return store.getters["securepay/paymentType"];
    });

    const additionalPayment = computed(() => {
      return store.getters["cart/additionalPayment"];
    });

    const cart = computed(() => {
      return store.getters["cart/cart"];
    });

    const totalSumWithVoucherDiscount = computed(() => {
      return numbersConvert.thousandSeparator(store.getters["cart/totalSumWithVoucherDiscount"]);
    });

    const grandTotalWithVoucherDiscount = computed(() => {
      return store.getters["cart/grandTotalWithVoucherDiscount"];
    });

    const customerPoints = computed(() => {
      return store.getters["auth/customerPoints"];
    });

    const pointsDiscount = computed(() => {
      return store.getters["cart/pointsDiscount"];
    });

    const voucherDiscount = computed(() => {
      return store.getters["cart/voucherDiscount"];
    });

    const loading = computed(() => {
      return store.getters["apiLoading/getLoading"];
    });

    const isCheckout = computed(() => {
      return stepper.value >= 2;
    });

    const stepper = computed(() => {
      return store.getters["cart/getStepper"];
    });

    const voucherActionType = computed(() => {
      return store.getters["cart/voucherActionType"];
    });

    const getPointsInput = async (input) => {
      input = parseInt(input.target.value);
      if (isNaN(input) || input == "") {
        input = 0;
      }
      await onPointsRedeem(input);
    };

    const debounce = (func, timeout = 600) => {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    };

    const pointDiscountChange = debounce((input) => getPointsInput(input));

    const orderOverview = async () => {
      await store.dispatch("cart/setStepper", 2);

      router.push({
        name: "bestellen",
        params: {},
      });
    };

    const onCheckboxChange = (event) => {
      if(event.target.name == "termsAccepted") {
        termsAccepted.value = event.currentTarget.checked;
      } else {
        conditionsAccepted.value = event.currentTarget.checked;
      }
    };

    const submitOrder = async (param) => {
      isDisabled.value = true;
      if (pointsDiscount.value > 0 || voucherActionType.value === "Fixed") {
        emit("submitOrderButtonClicked");
        isDisabled.value = false
        if (param != "orderSubmitted") {
          await store.dispatch("cart/trackCart", []);
        }
      } else {
        setNotification();
      }
    };

    const setNotification = () => {
      let notification = {
        code: "ORDER_RESPONSE",
        type: "error",
      };
      store.dispatch("notification/set", notification);
    };
    const login = () => {
      router.push({
        name: "my-shopping-cart",
        params: {},
      });
    };

    const logger = async () => {
      if (additionalPayment.value === 0) {
        await store.dispatch("cart/setStepper", 3);
        await store.dispatch("cart/trackCart", []);
        return;
      }

      await submitOrder();
    };

    // <!-- Commented trusted for now - we may need this here in future -->
    // const initTrustedBadger = () => {
    //   let _tsid = "X1B804ABF1400BAB98DCD909EAAD13EC5";
    //   let _ts = document.createElement("script");
    //   _ts.type = "text/javascript";
    //   _ts.charset = "utf-8";
    //   _ts.async = true;
    //   _ts.src = "//widgets.trustedshops.com/js/" + _tsid + ".js";
    //   let __ts = document.getElementsByTagName("script")[0];
    //   __ts.parentNode.insertBefore(_ts, __ts);
    // };

    watch(
      () => securepayPaymentType.value,
      async (current) => {
        if (current != "") {
          await store.dispatch("cart/setStepper", 3);
        }
      }
    );
    watch(
      () => props.products,
      () => {
        if (stepper.value == 1 && !isLoggedIn.value && route.name != "my-shopping-cart") {
          store.dispatch("cart/trackCart", props.products);
        }
      }
    );
    
    onMounted(async () => {
      await store.dispatch("cart/setStepper", props.step);
      if (additionalPayment.value > 0) {
        await initUserInputValue(pointsDiscount.value);
      } else {
        if (customerPoints.value > grandTotalWithVoucherDiscount.value) {
          await initUserInputValue(grandTotalWithVoucherDiscount.value);
        } else {
          await initUserInputValue(customerPoints.value);
        }
      }
      // initTrustedBadger();
    });

    return {
      totalSumWithVoucherDiscount,
      grandTotalWithVoucherDiscount,
      securepayPaymentType,
      isValidPointsAmount,
      isValid,
      applicableDiscount,
      alertText,
      alertTextAdditionalPayment,
      stepper,
      isLoggedIn,
      isCheckout,
      pointsDiscount,
      customerPoints,
      voucherDiscount,
      additionalPayment,
      getPointsInput,
      debounce,
      pointDiscountChange,
      voucherActionType,
      orderOverview,
      submitOrder,
      login,
      logger,
      loading,
      showVoucher,
      numbersConvert,
      cart,
      t,
      isDisabled,
      onCheckboxChange,
      areBothCheckboxesChecked
    };
  },
};
</script>
<style lang="postcss" scoped>
.checkout__summary {
  top: 0;
  position: sticky;
}

.checkout__table {
  @apply grid grid-cols-2 gap-4 grid-flow-row-dense bg-white p-4 mt-1;

  &--summary {
    border-color: rgba(0, 0, 0, 0.12);
  }

  &--button {
    border-color: rgba(0, 0, 0, 0.12);
    @apply pt-4;
  }

  &-redeemVoucherTable {
    @apply grid grid-cols-4 gap-0 grid-flow-row-dense bg-white;

    &_redeemButton {
      padding: 10px;
      height: 36px;
      width: 50px;
      @apply ml-2 mt-2;
    }
  }

  @media (max-width: 767px) {
    @apply mt-2;
  }
}

.roughBorder {
  @apply w-full;
  height: 56px;
}

.continue-shopping {
  height: 2rem;
  margin-left: 0.5rem;
  @apply flex text-black flex-col justify-center items-start;

  a {
    @apply ml-0 text-blue flex-row flex items-center justify-start;
  }
}
</style>
